import axios from "axios";
import $router from "@/router";
import { ElMessageBox } from "element-plus";
// 创建一个 axios 实例

const service = axios.create({
  baseURL: process.env.VUE_APP_KYLINPRO_URL, // 所有的请求地址前缀部分
  // baseURL: "https://rivers-uat.xsyxsc.cn/pro-api",
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    "Content-Type": "application/json",
    "Allow-Control-Allow-Origin": "*",
    tenantKey: "gys_outbrand_syste"
  }
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    const dataAxios = response.data;
    return dataAxios;
  },
  function (error) {
    if (
      error?.response?.status === 401 &&
      (error.request.responseURL ===
        "https://rivers-uat.xsyxsc.cn/pro-api/authorization/getUserInfoById" ||
        error.request.responseURL ===
          "https://rivers.xsyxsc.com/pro-api/authorization/getUserInfoById")
    ) {
      ElMessageBox.alert(
        error?.response?.data?.message || "登录已过期，请重新登录！",
        "提示",
        {
          confirmButtonText: "确定",
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false
        }
      ).then(() => {
        window.localStorage.clear();
        // 跳转到登录页
        $router.push("/login");
      });
    } else if (
      error?.response?.status &&
      (error.request.responseURL !==
        "https://rivers-uat.xsyxsc.cn/pro-api/authorization/newQuery" ||
        error.request.responseURL !==
          "https://rivers.xsyxsc.com/pro-api/authorization/newQuery")
    ) {
      $router.push(`/${error?.response?.status}`);
    }
    return Promise.reject(error);
  }
);

export default service;
