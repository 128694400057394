<template>
  <div id="tags-view" class="tags-view">
    <el-icon color="#929CA8" size="12px" class="moveLeftIcon"
      ><ArrowLeftBold
    /></el-icon>
    <scroll-pane ref="scrollPane" class="tags-view-wrapper">
      <div
        v-for="(tag, index) in tagList"
        ref="tag"
        :key="index"
        :class="isActive(tag) ? 'active' : ''"
        :to="{
          path: tag.linkUrl,
          fullPath: tag.linkUrl
        }"
        class="tags-view-item"
        @click.middle="isActive(tag) ? closeSelectedTag(tag, index) : ''"
        @contextmenu.prevent="openMenu(tag, $event)"
        @click="handleOpenMenu(tag)"
      >
        <span class="tagTitle">{{ tag.title }}</span>
        <el-icon
          v-show="tagList.length > 1 && isActive(tag)"
          class="el-icon-close"
          @click.stop="closeSelectedTag(tag, index)"
          ><CloseBold
        /></el-icon>
      </div>
    </scroll-pane>
    <el-icon color="#929CA8" size="12px" class="moveRightIcon"
      ><ArrowRightBold
    /></el-icon>
  </div>
</template>

<script>
import ScrollPane from "./ScrollPane";
import {
  ArrowLeftBold,
  ArrowRightBold,
  CloseBold
} from "@element-plus/icons-vue";

export default {
  name: "tagsView",
  components: { ScrollPane, ArrowLeftBold, ArrowRightBold, CloseBold },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      tags: this.$store.state.route.tagList
    };
  },
  computed: {
    tagList() {
      return this.tags;
    }
  },
  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    }
  },
  methods: {
    isActive(route) {
      return route.linkUrl === this.$route.path;
    },
    openMenu(tag, e) {
      const menuMinWidth = 80;
      const menuMinHeight = 24;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const { offsetWidth } = this.$el; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = menuMinHeight / 2;
      this.visible = true;
      this.selectedTag = tag;
    },
    handleOpenMenu(val) {
      this.$router.push({ path: val.linkUrl });
    },
    closeSelectedTag(val, index) {
      let length = this.tags.length - 1;
      if (val.linkUrl !== this.$route.path) {
        return;
      }
      this.$store.commit("route/delTagList", val);
      if (index === length) {
        if (length === 0) {
          if (this.$route.path !== "/index") {
            this.$router.push({ path: "/index" });
          }
        } else {
          this.$router.push({ path: this.tags[index - 1].linkUrl });
        }
      } else {
        this.$router.push({ path: this.tags[index].linkUrl });
      }
    },
    closeMenu() {
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.tags-view {
  height: 32px;
  width: 100%;
  position: relative;
  display: flex;
  .tags-view-wrapper {
    .tags-view-item {
      text-decoration: none;
      position: relative;
      margin-left: -1px;
      display: inline-block;
      padding: 0 30px 0 20px;
      color: #4d4d4d;
      font-size: 14px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      vertical-align: middle;
      background: #fff;
      height: 24px;
      line-height: 24px;
      border-radius: 6px;

      &:hover {
        background: #eff3ff;
        color: #2563eb;
        opacity: 0.8;
        border-radius: 6px;
        margin: 0px 5px;
      }

      &.active {
        background: #eff3ff;
        color: #2563eb;
        margin-left: 5px;
        &:hover {
          opacity: 0.8;
        }
      }

      //reset element css of el-icon-close
      /deep/ .el-icon-close {
        width: 14px;
        height: 14px;
        line-height: 10px;
        position: absolute;
        right: 5px;
        top: 6px;
        border-radius: 50%;
        text-align: center;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform-origin: 100% 50%;

        &:before {
          transform: scale(0.6);
          display: inline-block;
          vertical-align: -3px;
        }

        &:hover {
          background-color: #b4bccc;
          color: #fff;
        }
      }
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    /*box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);*/

    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }
  .moveRightIcon {
    margin-left: 16px;
  }
  .moveLeftIcon,
  .moveRightIcon {
    margin-top: 6px;
  }
  .moveLeftIcon {
    margin-right: 16px;
  }
}
</style>
