<template>
  <div class="page-error-401">
    <div class="tips-img" v-if="img">
      <img :src="img" />
    </div>
    <p v-if="text">{{ text }}</p>
    <div class="tips-button" v-if="isButton" @click="onButton">
      <el-button type="primary" plain>{{ buttonText }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-show",
  components: {},
  props: {
    text: {
      type: String,
      default: "您访问的页面不存在!"
    },
    img: {
      type: String,
      default:
        "https://front-xps-cdn.xsyx.xyz/custom/day360/2022/05/12/646366089.png"
    },
    isButton: {
      type: Boolean,
      default: true
    },
    isBackHome: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: "返回首页"
    }
  },
  data() {
    return {};
  },
  methods: {
    onButton() {
      if (this.isBackHome) {
        this.$store.dispatch("permission/$_selectedRoute", "HOTLINE");
        this.$router.replace({ path: "/login" });
      }
      this.$emit("onButton");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-error-401 {
  overflow: hidden;
  text-align: center;
  .tips-img {
    width: 200px;
    margin: 100px auto 10px auto;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }
  p {
    font-size: 24px;
    color: #3a8ee6;
    margin: 20px 0;
  }
}
</style>
