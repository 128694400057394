export async function routerBeforeEach(to, from, next) {
  const meta = to.meta;
  if (meta?.title === "登录页面") {
    next();
  } else if (meta?.requireAuth) {
    let token = localStorage.getItem("token");
    if (token) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    next();
  }
}

export function routerAfterEach() {}
