const state = {
  isCollapse: true
};
const mutations = {
  handleFold: (state, value) => {
    state.isCollapse = value;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
