// 导入axios实例
import httpRequest from "@/request/kylin";

// 获取平台菜单信息
export async function getUserMsg() {
  return httpRequest({
    url: "/authorization/newQuery",
    method: "get"
  });
}
// 获取用户信息
export async function getUserInfoById() {
  return httpRequest({
    url: "/authorization/getUserInfoById",
    method: "get"
  });
}
