<template>
  <div class="home">
    <img
      src="https://front-xps-cdn.xsyx.xyz/custom/day360/2022/05/12/646366089.png"
      class="informationOne"
    />
    <img
      src="https://front-xps-cdn.xsyx.xyz/custom/day360/2022/05/12/1399049891.png"
      class="informationTwo"
    />
    <img
      alt="Vue logo"
      src="https://front-xps-cdn.xsyx.xyz/custom/day360/2022/05/12/434141638.png"
      class="backImg"
    />
  </div>
</template>

<style scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
.backImg,
.informationOne,
.informationTwo {
  position: absolute;
}
.informationOne,
.informationTwo {
  z-index: 1;
  left: 64px;
}
.informationOne {
  top: 150px;
}
.informationTwo {
  top: 260px;
}
.backImg {
  height: 100%;
  width: 100%;
  border-radius: 6px;
}
</style>
