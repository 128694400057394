<template>
  <div class="login">
    <div class="loginTop">
      <img
        class="logoImg"
        src="https://front-xps-cdn.xsyx.xyz/custom/day360/2022/05/09/877607039.png"
      />
      <span class="title">兴盛优选 品牌数据平台</span>
    </div>
    <div>
      <div class="function">
        <div class="fontBox">
          <span class="name">手机号登录</span>
        </div>
        <el-input
          v-model="phoneNumber"
          placeholder="请输入手机号"
          clearable
          class="phoneNumber"
          @input="v => handlePhoneInput(v)"
          maxlength="11"
        />
        <div class="check">
          <el-input
            v-model="verification"
            placeholder="请输入验证码"
            class="verification"
            @input="value => handleInput(value)"
            maxlength="4"
          />
          <el-button
            class="informationBtn"
            plain
            color="#004AFD80"
            @click="handleSend()"
            :disabled="clickMessage"
            >{{ information }}</el-button
          >
          <div class="promptLanguage" v-show="promptLanguage">
            <img
              src="https://front-xps-cdn.xsyx.xyz/custom/day360/2022/04/27/958316221.png"
              class="icon"
            />
            验证码已发送，可能会有延迟请耐心等待
          </div>
        </div>
        <el-button
          :class="[signIn ? 'signIn' : 'land']"
          color="#004AFD"
          @click="toSendCode()"
          >登录</el-button
        >
        <div class="tips">
          温馨提醒：品牌商账号由兴盛优选总部授予，如未开通或者登录过程有疑问请致电：<span
            class="problem"
            >400-888-9997</span
          >
        </div>
      </div>
      <div class="error" v-if="error">{{ errorMsg }}</div>
    </div>
    <img
      src="https://front-xps-cdn.xsyx.xyz/custom/day360/2022/04/27/968991824.png"
      class="loginBackground"
    />
  </div>
</template>

<script>
import { apiGetPhone, apiGetToken } from "@/api/login";

//登录页面
export default {
  name: "loginBox",
  data() {
    return {
      error: false,
      phoneNumber: "", //手机号码
      verification: "", //验证码
      promptLanguage: false,
      totalTime: 60, //倒计时
      canClick: true,
      information: "获取验证码",
      errorMsg: "短信验证码已失效或者错误，请重新发送",
      signIn: true,
      clickMessage: true
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleInput(value) {
      if (/^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/.test(value)) {
        if (
          this.verification.length === 4 &&
          /^[1][3-9][\d]{9}/.test(this.phoneNumber)
        ) {
          this.signIn = false;
        } else {
          this.signIn = true;
        }
      } else {
        this.verification = value.replace(/[^\d]/g, "");
      }
    },
    handlePhoneInput(v) {
      if (/^[1][3-9][\d]{9}/.test(v)) {
        this.phoneNumber = v;
        this.clickMessage = false;
        if (this.verification.length === 4) {
          this.signIn = false;
        }
      } else {
        this.phoneNumber = v.replace(/[^\d]/g, "");
        this.signIn = true;
        this.clickMessage = true;
      }
    },
    handleSend() {
      if (!this.phoneNumber) {
        this.errorMsg = "请输入手机号!";
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 1000);
        this.clickMessage = true;
        this.signIn = true;
      } else if (!/^[1][3-9][\d]{9}/.test(this.phoneNumber)) {
        this.errorMsg = "请输入正确的手机号!";
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 1000);
        this.clickMessage = true;
        this.signIn = true;
      } else {
        this.clickMessage = false;
      }
      if (!this.canClick) {
        return;
      }
      this.verification = "";
      const param = {
        deviceId: this.phoneNumber,
        phoneNo: this.phoneNumber,
        codeType: 1
      };
      apiGetPhone(param).then(res => {
        const { code, message } = res;
        if (code === 1001) {
          //发送验证码成功
          this.promptLanguage = true;
          this.information = this.totalTime + "秒后重发";
          this.clickMessage = true;
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.information = this.totalTime + "秒后重发";
            if (this.totalTime < 0) {
              //当倒计时小于0时清除定时器
              window.clearInterval(clock); //关闭
              this.information = "重新发送验证码";
              this.totalTime = 60;
              this.canClick = true; //这里重新开启
              this.clickMessage = false;
              this.promptLanguage = false;
            }
          }, 1000);
        } else {
          this.errorMsg = message || "登录异常请联系工作人员处理";
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 1200);
        }
      });
    },
    toSendCode() {
      //对电话和验证码进行校验
      if (!!this.phoneNumber && !!this.verification) {
        //跳转到首页
        const param = {
          clientId: "gys_outbrand_syste",
          clientSecret: "gys_outbrand_syste",
          userName: this.phoneNumber,
          password: this.verification,
          loginType: 2,
          accountType: "",
          parentId: ""
        };
        apiGetToken(param).then(res => {
          const { code, data, message } = res;
          if (code === 1001) {
            localStorage.setItem("token", data?.access_token);
            setTimeout(() => {
              let path = "/index"; // '/user' 为个人中心页面
              if (this.$route.query.redirect) {
                path = this.$route.query.redirect; // 跳到之前的页面
              }
              this.$router.push({
                path: path
              });
            }, 1000);
          } else {
            this.errorMsg = message || "登录异常请联系工作人员处理";
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 1200);
          }
        });
      } else {
        if (!this.phoneNumber) {
          this.errorMsg = "请输入手机号!";
        } else if (!/^[1][3-9][\d]{9}/.test(this.phoneNumber)) {
          this.errorMsg = "请输入正确的手机号!";
        } else if (this.verification.length !== 4) {
          this.errorMsg = "请输入正确的验证码！";
        } else if (!this.verification) {
          this.errorMsg = "请输入验证码";
        } else {
          this.errorMsg = "短信验证码已失效或者错误，请重新发送";
        }
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginTop {
  display: flex;
  margin: 30px;
}
.loginBackground {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
}
.logoImg {
  margin-right: 10px;
}
.function {
  width: 520px;
  height: 456px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .fontBox {
    padding-top: 46px;
  }
}
.name {
  font-family: PingFang SC;
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  text-overflow: ellipsis;
}
.check {
  position: relative;
}
.promptLanguage {
  padding-top: 8px;
  font-weight: 400;
  font-size: 14px;
  height: 20px;
  color: #555555;
  display: flex;
  text-align: left;
  align-items: start;
}
.phoneNumber,
.verification,
.land,
.tips,
.signIn {
  width: 420px;
  height: 50px;
}
.phoneNumber,
.verification {
  margin-top: 32px;
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: #f5f5f6;
    border-radius: 8px;
    border: none;
  }
}
.land,
.signIn {
  margin-top: 50px;
  color: #fff !important;
}
.tips {
  margin-top: 14px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
}
.problem {
  color: #004afd;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: absolute;
  width: 187px;
  height: 80px;
  left: calc(50% - 97px);
  top: calc(50% - 40px);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  color: #fff;
  text-align: center;
}
.icon {
  padding: 0px 8px;
}
.informationBtn {
  position: absolute;
  top: 42px;
  right: 20px;
  color: #004afd !important;
  border: 1px solid #004afd !important;
  background: #f5f5f6 !important;
}
.signIn {
  opacity: 0.5;
}
.title {
  font-size: 36px;
  font-weight: 600;
  min-width: 400px;
}
</style>
