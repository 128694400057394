<template>
  <div class="page page-error-500">
    <error-show
      text="服务器内部错误"
      img="//front-xps-cdn.xsyx.xyz/2020/08/05/1204129554.png"
    />
  </div>
</template>

<script>
import errorShow from "./error-show";

export default {
  name: "error-500",
  components: {
    errorShow,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.page-error-500 {
  margin-top: 50px;
}
</style>
