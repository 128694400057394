import axios from "axios";
console.log(
  process.env.VUE_APP_KYLIN_URL,
  "process.env",
  process.env.VUE_APP_KYLINPRO_URL
);
// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_KYLIN_URL, // 所有的请求地址前缀部分
  // baseURL: "https://kylin.uat.xsyxsc.cn",
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    "Content-Type": "application/json",
    tenantKey: "gys_outbrand_syste"
  }
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    const dataAxios = response.data;
    return dataAxios;
  },
  function (error) {
    if (error?.response?.status) {
      this.$router.push(`/${error?.response?.status}`);
    }
    return Promise.reject(error);
  }
);

export default service;
