import Login from "@/views/login/login";
import Layout from "@/views/layout/index";
import error404 from "@/views/errorPage/404";
import error403 from "@/views/errorPage/403";
import error500 from "@/views/errorPage/500";
import Index from "@/views/pages/index.vue";
import Public from "@/views/pages/app.vue";
import error401 from "@/views/errorPage/401";

const routes = [
  {
    name: "登录",
    path: "/login",
    meta: {
      title: "登陆页面"
    },
    component: Login
  },
  {
    path: "/",
    name: "homeMenu",
    component: Layout,
    redirect: "/index",
    meta: {
      title: "首页",
      requireAuth: true //判定需要登陆页面
    },
    children: [
      {
        path: "index",
        name: "首页",
        component: Index
      },
      {
        path: "app/:id",
        component: Public
      }
    ]
  },
  {
    name: "error404",
    path: "/404",
    component: error404,
    meta: {
      title: "404"
    }
  },
  {
    path: "/403",
    name: "error403",
    component: error403,
    meta: {
      title: "403"
    }
  },
  {
    path: "/401",
    name: "error401",
    component: error401,
    meta: {
      title: "401"
    }
  },
  {
    path: "/500",
    name: "error500",
    component: error500,
    meta: {
      title: "500"
    }
  }
];

export default routes;
