// 导入axios实例
import httpRequest from "@/request/index";

// 获取验证码
export async function apiGetPhone(param) {
  return httpRequest({
    url: "/oauth/outside/token/getMessageValidCode",
    method: "post",
    data: param
  });
}
//校验验证码
export async function apiCheckPhone(param) {
  return httpRequest({
    url: "/oauth/outside/token/checkMessageValidCode",
    method: "post",
    data: param
  });
}

//获取登录的token
export async function apiGetToken(param) {
  return httpRequest({
    url: "/oauth/outside/token/login",
    method: "post",
    data: param
  });
}
