<template>
  <div class="page page-error-401">
    <error-show
      text="您的访问受限"
      img="//front-xps-cdn.xsyx.xyz/2020/08/04/1291816496.png"
    />
  </div>
</template>

<script>
import errorShow from "./error-show";

export default {
  name: "error-403",
  components: {
    errorShow,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
