<template>
  <router-view :key="key" />
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {
    key() {
      return this.$route.fullPath;
    }
  },
  created() {},
  mounted() {
    this.netStatus();
  },
  methods: {
    netStatus() {
      if (!navigator) {
        return false;
      }
      if (navigator.onLine) {
        console.log("on line!");
      } else {
        console.log("off line");
      }
      window.addEventListener("online", () => {
        console.log("you are online!");
      });
      window.addEventListener("offline", () => {
        this.$message.warning("当前已离线，请检查网络状况！");
      });
    }
  }
};
</script>
