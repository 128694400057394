<template>
  <div class="header">
    <div>
      <img
        src="https://front-xps-cdn.xsyx.xyz/custom/day360/2022/05/09/877607039.png"
        class="img"
      />
      <span class="name">兴盛优选 品牌数据平台</span>
    </div>
    <div class="funtion">
      <div class="information">
        <el-dropdown>
          <div class="box">
            <el-avatar :size="24" :src="circleUrl" />
            <span class="brandSide">{{ brandSide }}</span>
            <el-icon :size="10"><CaretBottom /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="brandSide">
                <el-avatar :size="24" :src="circleUrl" />
                <span class="brandSide">{{ brandSide }}</span>
                <el-icon :size="10"><CaretTop /></el-icon
              ></el-dropdown-item>
              <el-dropdown-item
                command="signOut"
                divided
                @click="handleSignOut()"
              >
                <i :class="`iconfont icon-a-Vector5`" class="signOut"></i
                >退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { CaretBottom, CaretTop } from "@element-plus/icons-vue";
import { getUserInfoById } from "@/api/kylin";

export default {
  name: "headerLayout",
  components: {
    CaretBottom,
    CaretTop
  },
  data() {
    return {
      brandSide: "",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      getUserInfoById().then(res => {
        const { code, data } = res;
        if (code === 1001) {
          this.brandSide = data?.userName || "";
        }
      });
    },
    handleSignOut() {
      window.localStorage.clear();
      // 跳转到登录页
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #f3f4f6;
}
.img {
  text-align: center;
  height: 23px;
  margin: 0px 10px 0px 18px;
}
.name {
  font-size: 15px;
  font-weight: 700;
  position: relative;
  top: -5px;
}
.funtion {
  display: flex;
  align-items: center;
}
.funtion,
.el-tooltip__trigger {
  margin-right: 24px;
}
.brandSide {
  margin: 0 8px;
  text-align: center;
}
.box {
  display: flex;
  align-items: center;
}
.signOut {
  font-size: 13px;
}
</style>
