const state = {
  routerList: [],
  tagList: []
};
const mutations = {
  routerListUpdate: (state, action) => {
    state.routerList = action;
  },
  addTagList: (state, view) => {
    //新增tags
    if (state.tagList.some(v => v.linkUrl === view.linkUrl)) return;

    state.tagList.push({ ...view, title: view.resourceName || "no-name" });
  },

  delTagList: (state, view) => {
    //删除tags
    if (state.tagList.length === 1) return;
    let result = state.tagList.findIndex(item => item.linkUrl === view.linkUrl);
    state.tagList.splice(result, 1);
  }
};

const actions = {
  addView({ commit }, view) {
    commit("tagList", view);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
