<template>
  <el-menu
    background-color="#2563eb1a"
    active-text-color="#2563EB"
    class="menu"
    :collapse="isCollapse"
    router
    :default-active="this.$route.path"
  >
    <div v-for="item in menuList" :key="item">
      <!-- 二级菜单 -->
      <el-menu-item
        :index="item.linkUrl"
        :key="item.linkUrl"
        v-if="item.nextItems === undefined"
        class="title"
        :route="item.route"
        @click="clickMenu(item)"
      >
        <!-- <i :class="`iconfont ${item.icon}`" class="icon" /> -->
        <span class="name">{{ item.resourceName }}</span>
      </el-menu-item>
      <!-- 三级菜单 -->
      <el-sub-menu :index="item.index" v-else class="title">
        <template #title>
          <i :class="`iconfont ${item.icon}`" class="icon" />
          <span class="name">{{ item.resourceName }}</span>
        </template>
        <div v-for="it in item?.nextItems" :key="it.index">
          <el-menu-item :index="it.index" :route="it.route">{{
            it.resourceName
          }}</el-menu-item>
        </div>
      </el-sub-menu>
    </div>
  </el-menu>
  <div class="fold" @click="handleFold">
    <el-icon :size="20" v-if="isCollapse"><Expand /></el-icon>
    <el-icon :size="20" v-else><Fold /></el-icon>
  </div>
</template>

<script>
import { getUserMsg } from "@/api/kylin";
import { Fold, Expand } from "@element-plus/icons-vue";

export default {
  name: "menuLayout",
  components: {
    Fold,
    Expand
  },
  data() {
    return {
      isCollapse: false,
      menuList: [
        {
          icon: "iconfont icon-a-Vector",
          linkUrl: "/index",
          parentId: "0",
          resourceCode: "home",
          resourceName: "首页",
          sort: 1,
          whetherGray: null,
          whetherShow: true
        }
      ]
    };
  },
  created() {
    this.getmenuList();
  },

  methods: {
    handleFold() {
      (this.isCollapse = !this.isCollapse),
        this.$store.commit("basics/handleFold", !this.isCollapse);
    },
    getmenuList() {
      getUserMsg().then(res => {
        const { code, data } = res;
        if (code === 1001) {
          localStorage.setItem("platformKey", data[0]?.platformKey);
          if (data.length === 1) {
            this.menuList = data[0]?.menuList;
          } else {
            data.forEach(item => {
              if (item === "menuList") {
                return this.menuList.push(item);
              }
              return;
            });
          }
          if (this.$store.state.route.tagList.length === 0) {
            this.menuList.forEach(item => {
              if (this.$route.path === item.linkUrl) {
                this.clickMenu(item);
              }
            });
          }
          this.$store.commit("route/routerListUpdate", this.menuList);
        }
      });
    },
    clickMenu(val) {
      this.$store.commit("basics/handleFold", true);
      this.$store.commit("route/addTagList", val);
    }
  }
};
</script>

<style scoped>
.menuList {
  position: relative;
}
.menu {
  height: calc(100% - 48px);
  background: #fff;
  border: none;
}
.title {
  height: 36px;
  margin: 6px;
}
.el-container {
  height: 100%;
}
.el-main {
  background-color: #004afd;
}
.icon:before {
  font-size: 12px;
  margin-right: 8px;
}
.name {
  font-size: 15px;
  font-weight: 600;
}
.el-icon .el-sub-menu__icon-arrow {
  height: 0 !important;
  width: 0 !important;
}
.el-menu-item.is-active {
  background: rgba(37, 99, 235, 0.1);
  border-left: 3px solid #2563eb;
}
.el-menu-item:hover {
  background: rgba(37, 99, 235, 0.1);
  color: #2563eb;
}
.el-menu-item {
  height: 36px;
  margin: 10px 0px;
}
.fold {
  display: flex;
  justify-content: center;
}
</style>
