import { createRouter, createWebHashHistory } from "vue-router";
import { routerBeforeEach, routerAfterEach } from "./intercept";
import routes from "./routes";

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(routerBeforeEach);
router.afterEach(routerAfterEach);
export default router;
