<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-container>
        <el-aside :width="subwidth">
          <Menu></Menu>
        </el-aside>
        <el-main>
          <div class="tagsBar">
            <Tagsview></Tagsview>
          </div>
          <div class="router">
            <router-view />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Menu from "./menu.vue";
import Header from "./header.vue";
import Tagsview from "./tags-view/index.vue";

export default {
  name: "homePage",
  components: {
    Menu,
    Header,
    Tagsview
  },
  data() {
    return {
      subwidth: "200px"
    };
  },
  watch: {
    "$store.state.basics.isCollapse": {
      handler(n) {
        if (this.$store.state.basics.isCollapse) {
          this.subwidth = "200px";
        } else {
          this.subwidth = "64px";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.common-layout {
  position: relative;
  height: 100vh;
  width: 100%;
  .el-header {
    padding: 0px;
    height: 48px;
  }
  .el-container {
    height: 100%;
  }
  .el-aside {
    overflow-x: hidden;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.03);
  }
  .el-main {
    padding: 14px;
  }
  .tagsBar {
    height: 36px;
  }
  .router {
    height: calc(100% - 36px);
  }
}
</style>
