<template>
  <div class="page page-error-404">
    <error-show />
  </div>
</template>

<script>
import errorShow from "./error-show";

export default {
  name: "error-404",
  components: {
    errorShow,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
