<template>
  <div :id="appDomId" style="height: 100%"></div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  name: "publicPage",
  data: () => ({
    appDomId: ""
  }),
  created() {
    this.appDomId = `app_${this.$route.params.id}`;
  },
  mounted() {
    const option = {
      name: "",
      mountId: this.appDomId,
      pageId: this.$route.params.id,
      versionName: "v0.1",
      moudle: "sdkView",
      props: {
        "kylinBi.header.token": "Bearer " + localStorage.getItem("token"),
        "kylinBi.header.tenantKey": "gys_outbrand_syste",
        "kylinBi.header.outsideBI": true,
        "kylinBi.header.platformKey": localStorage.getItem("platformKey")
      },
      hooks: {
        requestComplete: option => {
          const { error } = option;
          const { status, data } = error;
          console.log(error);
          if (
            status === 401 &&
            this.$route.params.id === "8c110f2a-b8a5-4eef-8e27-e10dd0dd8ea8"
          ) {
            ElMessage.error(
              "很抱歉,你没有该页面的数据权限!如需权限请去麒麟配置"
            );
          } else {
            ElMessage.error(data.message);
          }
        } // 请求结束的钩子函数
      }
    };
    setTimeout(() => {
      window.Ultron.createContainer(option).show();
    }, 0);
  }
};
</script>

<style>
.ant-message-notice-content {
  opacity: 0;
}
</style>
